import React, { useState, useEffect } from "react";
import "../styles/Navbar.css";
import Logo from '../assets/images/logo2.gif';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    // Check if the device is mobile
    if (window.matchMedia("(max-width: 768px)").matches) {
      setMenuOpen(!menuOpen);
      document.body.classList.toggle("menu-open", !menuOpen); // Toggle body class
    }
  };

  const closeMenuOnClick = (e) => {
    if (window.matchMedia("(max-width: 768px)").matches) {
      setMenuOpen(false);
      document.body.classList.remove("menu-open");
    }
    
    // Add active class to the clicked link
    const links = document.querySelectorAll('.nav-link');
    links.forEach(link => link.classList.remove('active'));  // Remove active class from all links
    e.target.classList.add('active'); // Add active class to the clicked link
  };
  

  // Close the menu when resizing from mobile to larger screens
  useEffect(() => {
    const handleResize = () => {
      if (!window.matchMedia("(max-width: 768px)").matches) {
        setMenuOpen(false);
        document.body.classList.remove("menu-open");
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const sections = document.querySelectorAll('.section');
    
    const options = {
      root: null, // use the viewport as the root
      threshold: 0.25, // Trigger when 25% of the section is visible
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
          observer.unobserve(entry.target);
        }
      });
    }, options);

    sections.forEach(section => {
      observer.observe(section);
    });

    return () => {
      sections.forEach(section => observer.unobserve(section));
    };
  }, []);

  return (
    <nav className="navbar">
      {/* Logo Section */}
      <div className="logo-container">
        <img src={Logo} alt="Logo" className="logo" />
        <span className="brand-name">Soft Wizzards</span>
      </div>

      {/* Hamburger Icon */}
      <div
        className={`hamburger ${menuOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      {/* Navigation Links */}
      <div className={`nav-links ${menuOpen ? "open" : ""}`}>
        <a href="#home" className="nav-link" onClick={closeMenuOnClick}>
          Home
        </a>
        <a href="#services" className="nav-link" onClick={closeMenuOnClick}>
          Services
        </a>
        <a href="#technologies" className="nav-link" onClick={closeMenuOnClick}>
          Technologies
        </a>
        <a href="#about" className="nav-link" onClick={closeMenuOnClick}>
          About
        </a>
        <a href="#contact" className="nav-link" onClick={closeMenuOnClick}>
          Contact
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
