import React, { useState, useEffect } from "react";
import "../styles/Services.css";

const Services = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Trigger animation on component mount
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100); // Delay for smooth entry

    return () => clearTimeout(timer); // Cleanup timeout on unmount
  }, []);

  return (
    <section className={`section section-left services ${isVisible ? "visible" : ""}`} id="services">
  <div className="particles">
    <div className="particle"></div>
    <div className="particle"></div>
    <div className="particle"></div>
    <div className="particle"></div>
    <div className="particle"></div>
  </div>
  <h2>Our Services</h2>
  <div className={`service fade-in`}>
    <h3>Web Development</h3>
    <p>Building responsive websites that adapt seamlessly across all devices and platforms.</p>
  </div>
  <div className={`service fade-in`}>
    <h3>Mobile App Development</h3>
    <p>Creating intuitive mobile apps for iOS and Android with great user experiences.</p>
  </div>
  <div className={`service fade-in`}>
    <h3>Cloud Computing</h3>
    <p>Providing scalable, secure, and reliable cloud solutions to optimize your business.</p>
  </div>
  <div className={`service fade-in`}>
    <h3>Search Engine optimization</h3>
    <p>Improving your website’s search engine ranking for better visibility and traffic.</p>
  </div>
  <div className={`service fade-in`}>
    <h3>Social Media Marketing</h3>
    <p>Boosting your brand presence with targeted social media campaigns to engage users.</p>
  </div>
  <div className={`service fade-in`}>
    <h3>Hosting Services</h3>
    <p>Reliable and fast hosting solutions ensuring your website’s optimal performance.</p>
  </div>
  <div className={`service fade-in`}>
    <h3>Domain Registration</h3>
    <p>Secure domain registration services to help you purchase and manage domains easily.</p>
  </div>
  <div className={`service fade-in`}>
    <h3>E-Commerce Solutions</h3>
    <p>Creating scalable online stores with Shopify, WooCommerce, and custom platforms.</p>
  </div>
</section>




  );
};

export default Services;
